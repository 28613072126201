<template>
  <div>
    <div class="authentication-bg">
      <div class="authentication-box">
        <div class="authentication-box-title">
          实名认证
        </div>
        <div class="authentication-box-submit">
          <van-field
            v-model="username"
            name="姓名"
            placeholder="请输入姓名"
            left-icon="friends-o
"
            class="user-name"
          />
          <van-field
            v-model="certificate"
            name="证件号码"
            placeholder="请输入证件号码"
            left-icon="label-o"
            class="user-certificate"
          />
          <div style="margin: 16px;">
            <van-button
              round
              @click="realNameAuthentication"
              class="certification-btn"
              >立即认证</van-button
            >
          </div>
        </div>
        <div class="authentication-prompt">
          <div class="authentication-prompt-content">
            <span
              >尊敬的用户，应国家相关部门要求，2021年6月1日起所有网络游戏用户须进行实名认证</span
            >
          </div>
        </div>
        <div class="real">
          <div class="real-title">
            <span>温馨提示:</span>
          </div>
          <div class="real-bbox">
            <div
              class="real-box"
              v-for="(item, index) in rules"
              :key="item + index"
            >
              <span>{{ item.content }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JSEncrypt from 'jsencrypt';
import { realAuthentication } from '@/service/moudules/wrapLogin';
import { userReport } from '@/service/moudules/wrapApi';
import { Toast } from 'vant';
export default {
  data() {
    return {
      idCard: '',
      realAuthentication,
      userReport,
      username: '',
      certificate: '',
      pis: '',
      rules: [
        {
          className: 'safe',
          content: '1.实名信息由国家系统进行认证，安全可靠',
        },
        {
          className: 'underage',
          content: '2.未成年人游戏时间、充值额度将受限制',
        },
        {
          className: 'realName',
          content:
            '3.根据国家新闻出版署《关于防止未成年人沉迷网络游戏的通知》《关于进一步严格管理切实防止未成年人沉迷网络游戏的通知》的要求，未实名用户将无法进入游戏，请您尽快完成实名认证！',
        },
      ],
    };
  },
  methods: {
    async realNameAuthentication() {
      try {
        //身份证res加密
        let encryptor = new JSEncrypt(); // 创建加密对象实例
        //之前ssl生成的公钥
        let pubKey =
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCNZfcaBQIU9Wpf/j9cbHhr5N8FlidUIpbZukRijGpKirX45EyI+JCxDgB4uo3qLrqQMIhwQPkqskp8963wEpDtwJ31HLLeijkbX871frtstTkjgBVMabZRkFsofxdRB2J9ZtIMuJqduNDTZqGV4PB/kOcGkq8JYJcSk988f+HgpwIDAQAB';
        encryptor.setPublicKey(pubKey); //设置公钥
        let rsaPassWord = encryptor.encrypt(this.certificate); // 对内容进行加密

        let params = {
          id: this.idCard,
          identityCard: rsaPassWord,
          realName: this.username,
        };

        let { data, msg, code } = await realAuthentication(params);

        if (code !== 200) {
          Toast({
            type: 'fail',
            message: msg,
          });
          return false;
        }
        let uParams = {
          no: 1,
          bt: 1,
          ot: new Date().getTime() / 1000,
          pi: this.pis,
        };

        let arr = [];

        arr.push(uParams);

        let paramsArr = {
          dataList: arr,
        };

        await userReport(paramsArr);
        Toast({
          type: 'success',
          message: '实名认证成功',
        });

        console.log(data, 'data');
        setTimeout(() => {
          window.location.href = `https://sdk.sh9130.com/partner/h5go/754/tjqy_tjqylch5lj_ATK/?userid=${this.idCard}`;
        }, 1500);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.idCard = this.$route.query.id;
    this.pis = this.$route.query.pis;
  },
};
</script>
<style lang="less" scoped>
.authentication-bg {
  height: 100vh;
  background-image: url('../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .authentication-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    height: 488px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.5);
    border-radius: 11px;
    border: 2px solid;
    // border-image: linear-gradient(
    //     224deg,
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.33),
    //     rgba(255, 255, 255, 0.08)
    //   )
    //   2 2;
    // backdrop-filter: contrast(1);
  }
  .authentication-box-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium;
    font-weight: 700;
  }
  .authentication-box-submit {
    margin-top: 15px;
    padding: 10px;
    .user-name {
      color: #000;
      background-color: #e5e7ed;
      border-radius: 15px;
    }
    .user-certificate {
      color: #000;
      margin-top: 20px;
      background-color: #e5e7ed;
      border-radius: 15px;
    }
    .certification-btn {
      width: 290px;
      background-image: linear-gradient(
        to bottom right,
        #785fba,
        #4d53b0,
        #2e4bb6
      );
      border-image: linear-gradient(to bottom right, #785fba, #4d53b0, #2e4bb6);
      color: #fff;
      font-size: 14px;
    }
  }
  .authentication-prompt {
    width: 300px;
    margin: 0 auto;
    .authentication-prompt-content {
      text-align: left;
      :nth-child(1) {
        overflow-wrap: break-word;
        color: #000;
        font-size: 12px;
        text-align: left;
        line-height: 17px;
      }
    }
  }
  .real {
    .real-title {
      text-align: left;
      padding: 15px;
      font-weight: 700;
      & span {
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .real-bbox {
      text-align: left;
      padding: 15px;
      .real-box {
        line-height: 20px;
        font-size: 12px;
        color: #000;
      }
    }
  }
}
</style>
